
///////////////////////
/// PADDINGS
.p {
  &-0 {
    padding: 0 !important;
  }

  &a {
    &-1 {
      padding: 1rem !important;
    }

    &-2 {
      padding: 2rem !important;
    }

    &-3 {
      padding: 3rem !important;
    }

    &-4 {
      padding: 4rem !important;
    }

    &-5 {
      padding: 5rem !important;
    }
  }

  &y {
    &-1 {
      padding-top: 1rem !important;
      padding-bottom: 1rem !important;
    }

    &-2 {
      padding-top: 2rem !important;
      padding-bottom: 2rem !important;
    }

    &-3 {
      padding-top: 3rem !important;
      padding-bottom: 3rem !important;
    }

    &-4 {
      padding-top: 4rem !important;
      padding-bottom: 4rem !important;
    }

    &-5 {
      padding-top: 5rem !important;
      padding-bottom: 5rem !important;
    }
  }

  &x {
    &-1 {
      padding-left: 1rem !important;
      padding-right: 1rem !important;
    }

    &-2 {
      padding-left: 2rem !important;
      padding-right: 2rem !important;
    }

    &-3 {
      padding-left: 3rem !important;
      padding-right: 3rem !important;
    }

    &-4 {
      padding-left: 4rem !important;
      padding-right: 4rem !important;
    }

    &-5 {
      padding-left: 5rem !important;
      padding-right: 5rem !important;
    }
  }

  &t {
    &-0 {
      padding-top: 0 !important;
    }

    &-1 {
      padding-top: 1rem !important;
    }

    &-2 {
      padding-top: 2rem !important;
    }

    &-3 {
      padding-top: 3rem !important;
    }

    &-4 {
      padding-top: 4rem !important;
    }

    &-5 {
      padding-top: 5rem !important;
    }
  }

  &b {
    &-1 {
      padding-bottom: 1rem !important;
    }

    &-2 {
      padding-bottom: 2rem !important;
    }

    &-3 {
      padding-bottom: 3rem !important;
    }

    &-4 {
      padding-bottom: 4rem !important;
    }

    &-5 {
      padding-bottom: 5rem !important;
    }
  }

  &l {
    &-1 {
      padding-left: 1rem !important;
    }

    &-2 {
      padding-left: 2rem !important;
    }

    &-3 {
      padding-left: 3rem !important;
    }

    &-4 {
      padding-left: 4rem !important;
    }

    &-5 {
      padding-left: 5rem !important;
    }
  }

  &r {
    &-1 {
      padding-right: 1rem !important;
    }

    &-2 {
      padding-right: 2rem !important;
    }

    &-3 {
      padding-right: 3rem !important;
    }

    &-4 {
      padding-right: 4rem !important;
    }

    &-5 {
      padding-right: 5rem !important;
    }
  }
}

///////////////////////
/// MARGINS
.m {
  &-0 {
    margin: 0;
  }

  &a {
    &-auto {
      margin: auto;
    }

    &-1 {
      margin: 1rem;
    }

    &-2 {
      margin: 2rem;
    }

    &-3 {
      margin: 3rem;
    }

    &-4 {
      margin: 4rem;
    }

    &-5 {
      margin: 5rem;
    }
  }

  &y {
    &-auto {
      margin-bottom: auto;
      margin-top: auto;
    }

    &-1 {
      margin: 1rem 0;
    }

    &-2 {
      margin: 2rem 0;
    }

    &-3 {
      margin: 3rem 0;
    }

    &-4 {
      margin: 4rem 0;
    }

    &-5 {
      margin: 5rem 0;
    }
  }

  &x {
    &-auto {
      margin-left: auto;
      margin-right: auto;
    }

    &-1 {
      margin: 0 1rem;
    }

    &-2 {
      margin: 0 2rem;
    }

    &-3 {
      margin: 0 3rem;
    }

    &-4 {
      margin: 0 4rem;
    }

    &-5 {
      margin: 0 5rem;
    }
  }

  &t {
    &-auto {
      margin-top: auto;
    }

    &-1 {
      margin-top: 1rem;
    }

    &-2 {
      margin-top: 2rem;
    }

    &-3 {
      margin-top: 3rem;
    }

    &-4 {
      margin-top: 4rem;
    }

    &-5 {
      margin-top: 5rem;
    }
  }

  &b {
    &-auto {
      margin-bottom: auto;
    }

    &-1 {
      margin-bottom: 1rem;
    }

    &-2 {
      margin-bottom: 2rem;
    }

    &-3 {
      margin-bottom: 3rem;
    }

    &-4 {
      margin-bottom: 4rem;
    }

    &-5 {
      margin-bottom: 5rem;
    }
  }

  &l {
    &-auto {
      margin-left: auto;
    }

    &-1 {
      margin-left: 1rem;
    }

    &-2 {
      margin-left: 2rem;
    }

    &-3 {
      margin-left: 3rem;
    }

    &-4 {
      margin-left: 4rem;
    }

    &-5 {
      margin-left: 5rem;
    }
  }

  &r {
    &-auto {
      margin-right: auto;
    }

    &-1 {
      margin-right: 1rem;
    }

    &-2 {
      margin-right: 2rem;
    }

    &-3 {
      margin-right: 3rem;
    }

    &-4 {
      margin-right: 4rem;
    }

    &-5 {
      margin-right: 5rem;
    }
  }
}
.w-100{
  width: 100%;
}

///////////////////////
/// BACKGROUNDS

.bg {
  &-primary {
    background-color: $color-primary;
  }

  &-blue-dark {
    background-color: $color-blue-darken;
  }

  &-grey-light {
    background-color: $color-gray-light;
  }
}

///////////////////////
/// TEXT COLORS
.text-color {
  &-primary {
    color: $color-primary;
  }
  &-blue {
    color: $color-blue;
  }
  &-blue-dark {
    color: $color-blue-dark;
  }
  &-blue-darken {
    color: $color-blue-darken;
  }
  &-green {
    color: $color-green;
  }
  &-white {
    color: #fff;
  }
  &-black {
    color: $color-black;
  }
}

.text {
  &--black {
    font-weight: 900;
  }
  &--heavy {
    font-weight: 800;
  }
  &--bold {
    font-weight: 700;
  }
  &--medium {
    font-weight: 500;
  }

  &--uppercase {
    text-transform: uppercase;
  }

  &--left {
    text-align: left;
  }

  &--center {
    text-align: center;
  }

  &--right {
    text-align: right;
  }

  &--lt {
    font-size: 1.6rem;
  }
}

///////////////////////
/// DISPLAY HELPERS

.d {
  &-inline-block {
    display: inline-block;
  }

  &-block {
    display: block;
  }

  &-flex {
    display: flex;
  }
}

.align {
  &-center {
    align-items: center;
  }

  &-start {
    align-items: flex-start;
  }

  &-end {
    align-items: flex-end;
  }
}

.justify {
  &-start {
    justify-content: flex-start;
  }

  &-center {
    justify-content: center;
  }

  &-end {
    justify-content: flex-end;
  }

  &-space-between {
    justify-content: space-between;
  }
  &-space-around{
    justify-content: space-around;
  }
}
.flex-direction {
  &-column {
    flex-direction: column;
  }

  &-column-reverse {
    flex-direction: column-reverse;
  }

  &-row {
    flex-direction: row;
  }

  &-row-reverse {
    flex-direction: row-reverse;
  }
}

.flex-wrap{
  flex-wrap: wrap;
}
