@import "@/styles/main.scss";

.faq {
  background-color: $color-blue-darken;
  color: #fff;
  padding: 9rem 0;
  position: relative;

  .h2 {
    line-height: 1;
  }

  &__left {
    width: 46%;
    @include respond(phone) {
      width: 100%;
      margin-bottom: 32px;
    }
  }

  &__right {
    width: 46%;
    @include respond(phone) {
      width: 100%;
    }
  }

  .decor-line {
    width: 100%;
    height: 28.4rem;
    overflow: hidden;
    position: absolute;
    bottom: 100%;
    left: 0;
    color: $color-blue-darken;

    &::before {
      content: '';
      width: 150%;
      height: 100%;
      background-color: currentColor;
      position: absolute;
      left: 0;
      top: 0;
      transform: skewY(-5deg);
      transform-origin: 100% 100%;
    }
  }
}
