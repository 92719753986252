$color-primary: #FF5F00;

$color-blue: #2D9CDB;
$color-blue-dark: #107EBC;
$color-blue-darken: #18336B;
$color-green: #27AE60;
$color-gray-light: #F5F0ED;
$color-black: #333333;
$color-white-dark: #f2f2f2;

$text-color: $color-black;

$main-font: 'SFProDisplay', sans-serif;
$second-font: 'SFProRounded', sans-serif;
