@import "@/styles/main.scss";

.bonus-recommend {
  padding: 4.5rem 0 8rem;
  @include respond(phone) {
    padding: 24px 0 40px;
  }
  .container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 3.2rem;
    align-items: flex-start;
    position: relative;
    @include respond(phone) {
      display: flex;
      flex-direction: column-reverse;
      grid-gap: unset;
    }
  }
  &__item {
    background-color: $color-gray-light;
    border-radius: 16px;
    padding: 5rem;
    position: relative;
    &:first-child {
      margin-top: 11rem;
      @include respond(phone) {
        margin-top: 24px;
      }
    }
    @include respond(phone) {
      padding: 24px;
    }

    .h5 {
      line-height: 130%;
      margin-bottom: .7187em;
      @include respond(phone) {
        margin-bottom: 16px;
      }
    }
    p{
      line-height: 130%;
    }

    .decor {
      position: absolute;
      bottom: 2rem;
      right: 0;
      transform: translateX(40%);
      width: 29.8rem;
      @include respond(phone) {
        transform: translateX(20%);
      }
      svg {
        width: 100%;
        height: 100%;
      }
    }
  }
}
.bonus-fake-btn {
  margin-top: 2.3rem;
  @include respond(phone) {
    margin-top: 16px;
  }
}
.fake-btn {
  display: block;
  width: calc(50% - 4px);
  font-weight: 800;
  color: #fff;
  background-color: $color-blue-dark;
  padding: .75em;
  border-radius: 8px;
  text-align: center;
  cursor: default;
  &--light {
    background-color: $color-blue;
  }
  @include respond(phone) {
    width: 100%;
    &:not(:last-child) {
      margin-bottom: 8px;
    }
  }
}
