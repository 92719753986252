@import "@/styles/main.scss";

.open-business {
  margin-top: 16rem;
  padding: 6.8rem 0 3.4rem;
  position: relative;
  @include respond(phone) {
    margin-top: 50px;
  }
  .container {
    position: relative;
    z-index: 1;
  }
  .decor-line {
    position: absolute;
    left: 0;
    bottom: 100%;
    height: 16rem;
    width: 100%;
    color: $color-gray-light;
    &::before {
      content: '';
      width: 150%;
      height: 100%;
      background-color: currentColor;
      transform: skewY(4.5deg);
      transform-origin: 0 0;
      position: absolute;
      left: 0;
      top: 0;
    }
    @include respond(phone) {
      height: 50px;
    }
  }

  &__top {
    &__title {
      width: calc(50% - 2.5rem);
      @include respond(phone) {
        width: 100%;
        margin-bottom: 16px;
        .title-text {
          br {
            display: none;
          }
        }
      }
    }
    .decor {
      position: relative;
      width: 13.2rem;
      svg {
        width: 100%;
        height: 100%;
      }
    }
  }
  &__descr {
    width: calc(50% - 2.5rem);
    @include respond(phone) {
      width: 100%;
    }
    p {
      &:not(:last-child) {
        margin-bottom: 1em;
      }
    }
  }

  &__features {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 5rem;
    margin-top: 8rem;
    @include respond(phone) {
      grid-template-columns: unset;
      margin-top: 40px;
    }
    &__item {
      display: flex;
      align-items: flex-start;
      margin-bottom: 5.6rem;
      .icon {
        flex: none;
        width: 5.7rem;
        margin-right: 1.6rem;
        @include respond(phone) {
          width: 57px;
          height: 57px;
          margin-right: 16px;
        }
        svg {
          width: 100%;
          height: 100%;
        }
      }
    }
  }

  &__description {
    &__left {
      width: calc(100% - (30.6rem + 9rem));
      @include respond(phone) {
        width: 100%;
      }
      p {
        max-width: 55rem;
        @include respond(phone) {
          max-width: unset;
          font-size: 16px;
        }
        &:not(:last-child) {
          margin-bottom: 1em;
          @include respond(phone) {
            margin-bottom: 24px;
          }
        }
      }
    }
    &__right {
      width: 30.6rem;
      height: 30.6rem;
      flex: none;
      border-radius: 50%;
      background-color: $color-blue-darken;
      color: #fff;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      text-align: center;
      position: relative;
      @include respond(phone) {
        width: 316px;
        height: 316px;
        margin: 10px auto 0;
      }
      .h5 {
        font-size: 4rem;
        font-weight: 700;
        @include respond(phone) {
          font-size: 48px;
        }
      }
      p {
        font-size: 2rem;
        max-width: 10em;
        @include respond(phone) {
          font-size: 20px;
        }
      }

      .decor {
        position: absolute;
        svg {
          width: 100%;
          height: 100%;
        }
        &--top{
          left: 0;
          top: 2.4rem;
          width: 4.5rem;
          @include respond(phone) {
            width: 36px;
            left: 24px;
          }
        }
        &--bottom-left {
          bottom: 2rem;
          right: 60%;
          width: 29.8rem;
          z-index: -1;
          @include respond(phone) {
            width: 300px;
          }
        }
        &--bottom-right {
          bottom: 0;
          left: 75%;
          width: 10rem;
          @include respond(phone) {
            width: 84px;
          }
        }
      }
    }
  }
}
