@import "@/styles/main.scss";

.who-we {
  padding: 8rem 0 5rem;
  @include respond(phone) {
    padding: 40px 0 30px;
  }

  .h2 {
    width: 35%;
    @include respond(phone) {
      margin-bottom: 24px;
    }
  }

  &__descr {
    width: 50%;

    .h5 {
      line-height: 130%;
    }
    p {
      line-height: 130%;
    }
  }

  .decor {
    margin: 2.7rem 0;
    width: 29.8rem;
    @include respond(phone) {
      margin: 16px 0;
    }
    svg {
      width: 100%;
      height: 100%;
    }
  }
}
.arrow-down-wrapper {
  padding-top: 5rem;
}
.arrow-down {
  width: 8rem;
  margin-left: auto;
  margin-right: auto;
  svg {
    width: 100%;
    height: 100%;
  }
}
