@import "@/styles/main.scss";

.main-screen {
  background-color: $color-blue-darken;
  padding: 2rem 0 9rem;
  @include respond(phone) {
    padding: 40px 0;
  }

  &__descr {
    width: 60%;
    padding-top: 8rem;
    @include respond(phone) {
      padding-top: 0;
      margin-bottom: 23px;
    }

    .h1 {
      line-height: 1;
      margin-bottom: .3em;
      @include respond(phone) {
        margin-bottom: 16px;
      }
    }

    .h6 {
      line-height: 1.2;
      margin-bottom: .9em;
      max-width: 49rem;
      @include respond(phone) {
        max-width: unset;
        margin-bottom: 16px;
        line-height: 1.3;
      }
    }

    p {
      font-size: 2rem;
      max-width: 57rem;
      line-height: 1.2;
      @include respond(phone) {
        font-size: 14px;
        line-height: 1.3;
        max-width: unset;
      }
    }
  }

  &__img {
    width: 40%;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: end;

    & > img {
      width: 75rem;
      position: relative;
      transform: translateX(35%);
      z-index: 1;
      @media(max-width: 1400px) {
        width: 60rem;
        transform: translateX(25%);
      }
      @include respond(phone) {
        width: 75rem;
        transform: translateX(25%);
      }
    }
  }

  .decor {
    position: absolute;

    svg {
      width: 100%;
      height: 100%;
    }

    &--top {
      z-index: 2;
      top: 1rem;
      left: 10%;
      width: 8.7rem;
      @include respond(phone) {
        left: auto;
        right: 38rem;
      }
    }

    &--bottom {
      bottom: 11%;
      right: 0;
      width: 100rem;
      @media(max-width: 1400px) {
        width: 50rem;
      }
      @include respond(phone) {
        width: 70rem;
        right: -3rem;
      }
    }
  }
}
