@import "@/styles/main.scss";

.accordion-item {
  border-bottom: 1px solid rgba(224, 224, 224, 0.3);
  padding: 1.6rem 0;
  @include respond(phone) {
    padding: 16px 0;
  }

  &:first-child {
    border-top: 1px solid rgba(224, 224, 224, 0.3);
  }

  &__title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 2rem;
    line-height: 130%;
    font-weight: 800;
    cursor: pointer;
    @include respond(phone) {
      font-size: 14px;
    }

    &.open {
      .icon {
        &::after {
          transform: translate(-50%, -50%) rotate(0);
        }
      }
    }

    .icon {
      width: 4.8rem;
      height: 4.8rem;
      flex: none;
      border-radius: 50%;
      background-color: $color-primary;
      color: #fff;
      position: relative;
      margin-left: 2rem;
      @include respond(phone) {
        width: 32px;
        height: 32px;
        margin-left: 20px;
      }

      &::before,
      &::after {
        content: '';
        width: 50%;
        height: 2px;
        background-color: currentColor;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }

      &::after {
        transform: translate(-50%, -50%) rotate(90deg);
      }
    }
  }

  &__content {
    margin-top: 3rem;
    line-height: 1.5;
    @include respond(phone) {
      margin-top: 20px;
      font-size: 14px;
    }
  }
}
.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}
.fade-enter-from, .fade-leave-to  {
  opacity: 0;
}
