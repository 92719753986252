@font-face {
  font-family: "SFProDisplay";
  src: url("@/assets/fonts/SFProDisplay/SFProDisplay-Black.woff") format("woff");
  font-style: normal;
  font-weight: 900;
  font-display: swap;
}
@font-face {
  font-family: "SFProDisplay";
  src: url("@/assets/fonts/SFProDisplay/SFProDisplay-Heavy.woff") format("woff");
  font-style: normal;
  font-weight: 800;
  font-display: swap;
}
@font-face {
  font-family: "SFProDisplay";
  src: url("@/assets/fonts/SFProDisplay/SFProDisplay-Bold.woff") format("woff");
  font-style: normal;
  font-weight: 700;
  font-display: swap;
}
@font-face {
  font-family: "SFProDisplay";
  src: url("@/assets/fonts/SFProDisplay/SFProDisplay-Semibold.woff") format("woff");
  font-style: normal;
  font-weight: 600;
  font-display: swap;
}
@font-face {
  font-family: "SFProDisplay";
  src: url("@/assets/fonts/SFProDisplay/SFProDisplay-Medium.woff") format("woff");
  font-style: normal;
  font-weight: 500;
  font-display: swap;
}
@font-face {
  font-family: "SFProDisplay";
  src: url("@/assets/fonts/SFProDisplay/SFProDisplay-Regular.woff") format("woff");
  font-style: normal;
  font-weight: 400;
  font-display: swap;
}

@font-face {
  font-family: "SFProRounded";
  src: url("@/assets/fonts/SFProRounded/SFRounded-Black.woff") format("woff");
  font-style: normal;
  font-weight: 900;
  font-display: swap;
}
@font-face {
  font-family: "SFProRounded";
  src: url("@/assets/fonts/SFProRounded/SFRounded-Heavy.woff") format("woff");
  font-style: normal;
  font-weight: 800;
  font-display: swap;
}
@font-face {
  font-family: "SFProRounded";
  src: url("@/assets/fonts/SFProRounded/SFRounded-Regular.woff") format("woff");
  font-style: normal;
  font-weight: 400;
  font-display: swap;
}
