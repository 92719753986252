@import "@/styles/main.scss";

.menu {
  font-size: 14px;
  line-height: 150%;
  display: flex;
  align-items: center;
  @include respond(phone) {
    font-size: 14px;
  }
  li {
    margin-right: 2.2em;
    cursor: pointer;
    transition: all .3s;
    @include respond(phone) {
      margin-right: 1em;
    }
    &:hover {
      color: $color-primary
    }
  }
  &.flex-direction-column {
    li {
      margin-right: 0;
      &:not(:last-child) {
        margin-bottom: 8px;
      }
    }
  }
}
