@import "@/styles/main.scss";

.bonus {
  &-block {
    max-width: 930px;
    background-color: #F5F0ED;
    border-radius: 16px;
    padding: 5.6rem;
    position: relative;
    @include respond(phone) {
      padding: 16px;
    }

    & > .h5 {
      margin-bottom: .5em;
      @include respond(phone) {
        max-width: 160px;
        margin-bottom: 16px;
      }
    }

    &__item {
      background-color: #fff;
      border-radius: 16px;
      padding: 2.4rem 4rem;
      @include respond(phone) {
        font-size: 12px;
        border-radius: 8px;
        padding: 8px 16px;
        .text-size-medium {
          font-size: 12px;
        }
      }

      &:not(:last-child) {
        margin-bottom: 1.6rem;
        @include respond(phone) {
          margin-bottom: 16px;
        }
      }

      p {
        line-height: 130%;
      }
    }

    &__notice {
      width: 24.4rem;
      height: 24.4rem;
      border-radius: 50%;
      font-size: 1.6rem;
      line-height: 130%;
      padding: 1em 1.5em 1em 2em;
      background-color: $color-blue;
      color: #fff;
      position: absolute;
      left: 100%;
      top: 0;
      transform: translateX(-30%);
      @include respond(tab-land) {
        transform: translateX(0);
        left: auto;
        right: 0;
        top: -2rem  ;
      }
      @include respond(phone) {
        font-size: 12px;
        width: 136px;
        height: 136px;
        top: -40px;
      }
    }
  }
}

.bonus-list {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 1.6rem;
  margin-top: 1.6rem;
  &--lg {
    @include respond(phone) {
      grid-template-columns: repeat(2, 1fr);
    }
  }
  @include respond(phone) {
    margin-top: 16px;
    grid-gap: 5px;
  }

  &__item {
    text-align: center;
    font-size: 1.6rem;
    line-height: 130%;

    &__title {
      border-radius: 8px 8px 0 0;
      color: $color-white-dark;
      font-weight: 800;
      text-transform: uppercase;
      padding: .5em;
      @include respond(phone) {
        font-size: 10px;
      }

      &.beginner {
        background-color: #C84A00;
      }

      &.prof {
        background-color: #E95700;
      }

      &.expert {
        background-color: #FF5F00;
      }

      &.master {
        background-color: #FF8E4A;
      }
    }

    &__value {
      padding: .5em;
      border-radius: 0 0 8px 8px;
      background: rgba(255, 95, 0, 0.09);
      @include respond(phone) {
        font-size: 12px;
      }
    }
  }
}
