@import "@/styles/main.scss";

.header {
  padding: 1.5rem 0;
}
.btns-container{
  margin-right: 2rem;
}
.btn {
  display: inline-flex;
  align-items: center;
  font-weight: 600;
  font-size: 12px;
  line-height: 1;
  text-transform: uppercase;
  border-radius: 30px;
  padding: 5px 20px;
  height: 40px;
  @include respond(phone) {
    padding: 5px;
  }
  &--primary {
    background-color: $color-primary;
    color: #fff;
    &:hover {
      background: darken($color-primary, 10%);
    }
  }
  &--outline {
    border: 2px solid #D4D7DD;
    &:hover {
      color: $color-primary;
      border-color: currentColor;
      path {
        stroke: currentColor;
      }
    }
  }
  .icon {
    margin-right: .5rem;
    transition: all .1s;
  }
}
