@import "@/styles/main.scss";

.social {
  li {
    &:not(:last-child) {
      margin-right: 2rem;
    }

    a {
      color: currentColor;

      &:hover {
        color: $color-primary
      }
      .icon {
        display: block;
        width: 24px;
        height: 24px;
        svg {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
}
