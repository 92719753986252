@import "mixins";
.main-font {
  font-family: $main-font;
}
.second-font {
  font-family: $second-font;
}

h1, .h1 {
  font-size: 10rem;
  @include respond(phone) {
    font-size: 40px;
  }
}
h2, .h2 {
  font-size: 8rem;
  @include respond(phone) {
    font-size: 32px;
  }
}
h3, .h3 {
  font-size: 5.6rem;
}
h4, .h4 {
  font-size: 4.8rem;
  @include respond(phone) {
    font-size: 24px;
  }
}
h5, .h5 {
  font-size: 3.2rem;
  @include respond(phone) {
    font-size: 18px;
  }
}
h6, .h6,
.text-size-medium{
  font-size: 2.4rem;
  @include respond(phone) {
    font-size: 16px;
  }
}
p {
  font-size: 1.8rem;
  line-height: 1.3;
  @include respond(phone) {
    font-size: 12px;
  }
}
