@import "@/styles/main.scss";

.footer {
  color: #fff;
  background-color: $color-black;

  &-main {
    padding: 10.8rem 0 8.8rem;
    @include respond(phone) {
      padding: 40px 0;
    }

    &__left {
      width: 50%;
      @include respond(phone) {
        width: 100%;
        margin-bottom: 24px;
      }

      .h6 {
        margin-bottom: 1.6rem;
        @include respond(phone) {
          margin-bottom: 16px;
        }
      }

      .decor {
        margin: 4rem 0;
        width: 29.7rem;
        @include respond(phone) {
          margin: 16px 0;
        }

        svg {
          width: 100%;
          height: 100%;
        }
      }

      p {
        line-height: 1.5;
      }
    }

    &__right {
      max-width: 41.6rem;
      @include respond(phone) {
        max-width: unset;
      }
    }
  }

  &__bottom {
    padding: 4rem 0;
    background-color: #2C2C2C;
    @include respond(tab-port) {
      .container {
        align-items: flex-start;
        @include respond(phone) {
          display: block;
        }
      }
    }
    @include respond(phone) {
      padding: 24px 0;
    }

    &__left {
      width: 55%;
      @include respond(tab-port) {
        width: 100%;
        flex-wrap: wrap;
      }
      @include respond(phone) {
        margin-bottom: 30px;
      }
    }

    &__menu-wrapper {
      flex-grow: 1;
      @include respond(tab-port) {
        flex-grow: unset;
        width: 100%;
      }
      @include respond(phone) {
        display: block !important;
      }

      & > *:not(:last-child) {
        margin-right: 4rem;
        @include respond(phone) {
          margin-right: 0;
          margin-bottom: 30px;
        }
      }
    }
  }
}

.join {
  background: rgba(255, 255, 255, 0.2);
  border-radius: 24px;
  color: #fff;
  padding: 4.8rem;
  @include respond(phone) {
    padding: 24px;
  }

  p {
    margin-bottom: 1em;
    @include respond(phone) {
      font-size: 13px;
      margin-bottom: 24px;
    }
  }

  .btn {
    display: block;
    border: 3px solid #FFFFFF;
    border-radius: 56px;
    font-size: 2.4rem;
    padding: 1em .3em;
    text-align: center;
    text-transform: uppercase;
    font-weight: 700;
    cursor: pointer;
    transition: all .3s;

    &:hover {
      background-color: $color-primary;
      border-color: $color-primary;
    }

    @include respond(phone) {
      font-size: 16px;
      padding: 1.35em 1em;
    }
  }
}

.company-name {
  font-size: 14px;
  color: #7B7B7B;
  line-height: 150%;
  margin-right: 5em;
  white-space: nowrap;
  @include respond(tab-port) {
    width: 100%;
    margin-bottom: 30px;
    margin-right: 0;
  }
}

.footer-menu {
  font-size: 14px;
  line-height: 150%;
  white-space: nowrap;

  li {
    &:not(:last-child) {
      margin-bottom: 8px;
    }
    a {
      &:hover {
        color: $color-primary
      }
    }
  }
}
