///////////////////////
///MEDIA QUERY MANAGER

/*
$breakpoint argument choisr
- phone
- tab-port
- tab-land
- big-desktop
*/

@mixin respond($beakpoint) {
  @if $beakpoint == phone {
    @media (max-width: 640px) { @content }; //640px
  }
  @if $beakpoint == tab-port {
    @media (max-width: 991px) { @content }; //991px
  }
  @if $beakpoint == tab-land {
    @media (max-width: 1280px) { @content }; //1280px
  }
  @if $beakpoint == small-desktop {
    @media (max-width: 1440px) { @content }; //1440
  }
  @if $beakpoint == big-desktop {
    @media (min-width: 1800px) { @content }; //1800
  }
  @if $beakpoint == huge-desktop {
    @media (min-width: 2000px) { @content }; //2000
  }
}
