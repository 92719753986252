* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}
ul, ol, li {
  list-style-type: none;
}
html {
  font-size: 10px;
  @include respond(small-desktop) {
    font-size: .8vw;
  }
  @include respond(phone) {
    font-size: 5px;
  }
}
body {
  font-family: $main-font;
  color: $text-color;
  font-weight: 400;
  background-color: #fff;
  font-size: 1.8rem;
  line-height: 1.3;
}
.container {
  width: 100%;
  max-width: 1248px;
  padding: 0 24px;
  margin: 0 auto;
}
a {
  color: currentColor;
  text-decoration: none;
  transition: all .3s;
}
.w-100 {
  width: 100% !important;
}
