@import "@/styles/main.scss";

.features {
  padding: 4.5rem 0;
  background-color: $color-gray-light;
  position: relative;
}
.features-list {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  position: relative;
  z-index: 2;
  &__item {
    width: 45%;
    margin-bottom: 7rem;
    @include respond(phone) {
      width: 100%;
      margin-bottom: 48px;
    }
    &:nth-child(even) {
      padding-left: 9rem;
      @include respond(phone) {
        padding-left: 0;
      }
    }
    &--img {
      @include respond(phone) {
        order: 8
      }
    }
    .icon {
      line-height: 0;
      margin-bottom: 1.3rem;
      @include respond(phone) {
        margin-bottom: 10px;
      }
      &--bonus {
        margin-left: -5rem;
        margin-bottom: -2rem;
        @include respond(tab-port) {
          margin-left: 0;
          margin-bottom: 0;
        }
        @include respond(phone) {
          margin-bottom: 10px;
        }
      }
    }
    .h6 {
      font-weight: 800;
      margin-bottom: .75em;
      @include respond(phone) {
        margin-bottom: 8px;
      }
    }
    p {
      max-width: 44.6rem;
      &:not(:last-child) {
        margin-bottom: 1em;
      }
      @include respond(phone) {
        font-size: 14px;
        max-width: unset;
      }
    }

    &__img {
      position: relative;
      width: 57.4rem;
      @include respond(tab-port) {
        width: 100%;
      }
      @include respond(phone) {
        width: 240px;
      }
      &::before {
        content: '';
        width: 100%;
        height: 100%;
        border-radius: 25px;
        background-color: $color-primary;
        position: absolute;
        top: 5rem;
        left: 8rem;
        @include respond(tab-port) {
          top: 3rem;
          left: 4rem;
        }
        @include respond(phone) {
          top: 30px;
          left: 30px;
        }
      }
      img {
        width: 100%;
        border-radius: 25px;
        position: relative;
        z-index: 1;
      }
    }
  }
}
