@import "@/styles/main.scss";

.lang {
  position: relative;
  &-active {
    cursor: pointer;
  }
  .icon {
    display: inline-flex;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    overflow: hidden;
    svg {
      width: 100%;
      height: 100%;
    }
  }
  &-list {
    position: absolute;
    top: 100%;
    right: -20px;
    background-color: #fff;
    border-radius: 16px;
    padding: 8px 2px;
    //width: 230px;
    box-shadow: -2px 4px 10px rgba(0, 0, 0, .1);
    z-index: 10;
    li {
      font-size: 16px;
      padding: 10px 16px;
      display: flex;
      align-items: center;
      cursor: pointer;
      border-radius: 8px;
      &:hover {
        background: #F2F2F2;
      }
    }
  }
}
